export default {
    methods: {
        fileOpenSwitch(file) {
            if(['pdf'].includes(file.extension)) {
                this.$nextTick(() => {
                    this.$refs[`file_dropdown_${file.id}`].openPreviewModal()
                })
            }
        }
    }
}